$width: 40px;
$height: 40px;

.lds-facebook {
  display: inline-block;
  position: relative;
  width: $width;
  height: $height;

  div {
    display: inline-block;
    position: absolute;
    left: calc($width / 10);
    width: calc($width / 5);
    background: #fff;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

    &:nth-child(1) {
      left: calc($width / 10);
      animation-delay: -0.24s;
    }

    &:nth-child(2) {
      left: calc($width / 2.5);
      animation-delay: -0.12s;
    }

    &:nth-child(3) {
      left: calc($width / 1.43);
      animation-delay: 0;
    }
  }
}

@keyframes lds-facebook {
  0% {
    top: calc($height / 10);
    height: calc($height / 1.25);
  }
  50%, 100% {
    top: calc($height / 3.33);
    height: calc($height / 2.5);
  }
}